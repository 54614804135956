import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import './EventPage.css';
import 'bootstrap/dist/css/bootstrap.min.css';

function EventPage() {
    const { eventId } = useParams();
    const [event, setEvent] = useState(null);
    const [venue, setVenue] = useState(null);
    const [artists, setArtists] = useState([]);

    useEffect(() => {
        const fetchEvent = async () => {
            const eventRef = doc(db, 'events', eventId);
            const eventDoc = await getDoc(eventRef);
            if (eventDoc.exists()) {
                const eventData = eventDoc.data();
                setEvent(eventData);

                if (eventData.venueId) {
                    const venueRef = doc(db, 'venues', eventData.venueId);
                    const venueDoc = await getDoc(venueRef);
                    if (venueDoc.exists()) {
                        setVenue(venueDoc.data());
                    }
                }

                if (eventData.artists && eventData.artists.length > 0) {
                    const artistDocs = await Promise.all(
                        eventData.artists.map(artistId =>
                            getDoc(doc(db, 'artists', artistId))
                        )
                    );
                    setArtists(artistDocs.map(doc => doc.data()));
                }
            }
        };

        fetchEvent();
    }, [eventId]);

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${parseInt(month, 10)}/${day}/${year}`;
    };

    // Format time function
    const formatTime = (time) => {
        const [hour, minute] = time.split(':');
        const suffix = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}:${minute} ${suffix}`;
    };

    // Format age requirement function
    const formatAgeRequirement = (age) => {
        return age === 'all' ? 'All Ages' : `${age}+`;
    };

    if (!event) return <div>Loading...</div>;

    return (
        <div className="event-page-container">
            <div className="container event-page-content">
                <div className="row justify-content-center">
                    <div className="col-md-8 text-center">
                        <img
                            src={event.flyerUrl}
                            alt={`${event.title} Flyer`}
                            className="img-fluid rounded flyer-img mb-4 border-red"
                        />
                        <p className="promoters-present text-warning">
                            {event.promoters} present
                        </p>
                        <h1 className="event-title text-danger">{event.title}</h1>
                        <p className="event-date-time">{formatDate(event.date)}</p>
                        <p className="event-time">{formatTime(event.startTime)} {event.endTime ? `- ${formatTime(event.endTime)}` : ''}</p>
                        <p>{formatAgeRequirement(event.ageRequirement)}</p>
                    </div>
                </div>

                {/* Venue Section */}
                {venue && (
                    <div className="row justify-content-center mt-4">
                        <div className="col-md-6 text-center">
                            <h2 className="text-danger">Venue</h2>
                            <p><FontAwesomeIcon icon={faMapMarkerAlt} /> {venue.name}</p>
                            <p>{venue.address}</p>
                            {venue.googleMapsIframe && (
                                <div className="map-embed" dangerouslySetInnerHTML={{ __html: venue.googleMapsIframe }} />
                            )}
                        </div>
                    </div>
                )}

                {/* Artists Section */}
                {artists.length > 0 && (
                    <div className="row justify-content-center mt-4">
                        <div className="col-md-10">
                            <h2 className="text-danger text-center">Performing Artists</h2>
                            <div className="row">
                                {artists.map(artist => (
                                    <div key={artist.id} className="col-md-4 mb-4">
                                        <div className="card artist-card h-100">
                                            <img
                                                src={artist.imageUrl}
                                                alt={artist.name}
                                                className="card-img-top"
                                            />
                                            <div className="card-body">
                                                <h3 className="card-title">{artist.name}</h3>
                                                <p className="card-text">{artist.bio}</p>
                                                <div className="artist-links">
                                                    {artist.soundCloud && (
                                                        <a
                                                            href={artist.soundCloud}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="btn btn-secondary btn-sm"
                                                        >
                                                            SoundCloud
                                                        </a>
                                                    )}
                                                    {artist.instagram && (
                                                        <a
                                                            href={artist.instagram}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="btn btn-secondary btn-sm ms-2"
                                                        >
                                                            Instagram
                                                        </a>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )}

                {/* Event Buttons */}
                <div className="row justify-content-center mt-4">
                    <div className="col-md-6 text-center">
                        {event.facebookEventLink && (
                            <a href={event.facebookEventLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary me-3">
                                <FontAwesomeIcon icon={faFacebookF} /> Facebook Event
                            </a>
                        )}
                        {event.ticketLink && (
                            <a href={event.ticketLink} target="_blank" rel="noopener noreferrer" className="btn btn-success">
                                <FontAwesomeIcon icon={faTicketAlt} /> Buy Tickets
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EventPage;