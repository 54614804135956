import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTicketAlt, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as filledStar } from '@fortawesome/free-solid-svg-icons';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook
import { db } from '../firebase'; // Firestore reference

function EventCard({ event, editEvents, handleEdit }) {
    const [venue, setVenue] = useState(null);
    const [isFeatured, setIsFeatured] = useState(event.isFeatured || false);
    const navigate = useNavigate(); // Initialize useNavigate

    useEffect(() => {
        const fetchVenue = async () => {
            if (event.venueId) {
                try {
                    const venueRef = doc(db, 'venues', event.venueId);
                    const venueDoc = await getDoc(venueRef);
                    if (venueDoc.exists()) {
                        setVenue(venueDoc.data());
                    } else {
                        console.error("Venue not found for event:", event.id);
                    }
                } catch (error) {
                    console.error("Error fetching venue for event:", event.id, error);
                }
            }
        };
        fetchVenue();
    }, [event.venueId, event.id]);

    const formatDate = (dateString) => {
        const [year, month, day] = dateString.split('-');
        return `${parseInt(month, 10)}/${day}/${year}`;
    };

    // Format time function
    const formatTime = (time) => {
        const [hour, minute] = time.split(':');
        const suffix = hour >= 12 ? 'PM' : 'AM';
        const formattedHour = hour % 12 || 12;
        return `${formattedHour}:${minute} ${suffix}`;
    };

    // Format age requirement function
    const formatAgeRequirement = (age) => {
        return age === 'all' ? 'All Ages' : `${age}+`;
    };

    // Toggle feature status
    const toggleFeatured = async () => {
        try {
            const eventRef = doc(db, 'events', event.id);
            await updateDoc(eventRef, { isFeatured: !isFeatured });
            setIsFeatured(!isFeatured); // Update the UI instantly
        } catch (error) {
            console.error("Error updating featured status:", error);
        }
    };

    // Click handler to navigate to the event detail page
    const handleCardClick = () => {
        navigate(`/event/${event.id}`); // Navigate to the event detail page
    };

    return (
        <div className="col-md-6 mb-4" onClick={handleCardClick} style={{ cursor: 'pointer' }}>
            <div className="card bg-dark text-white">
                <div className="card-body d-flex flex-column align-items-center">
                    {/* Event Flyer */}
                    <img
                        src={event.flyerUrl}
                        alt={`${event.title} Flyer`}
                        className="event-flyer img-fluid mb-3"
                        style={{ maxWidth: '100%', borderRadius: '10px', boxShadow: '0 4px 8px rgba(255, 0, 0, 0.7)' }}
                    />

                    {/* Promoters Present */}
                    <p className="promoters-present text-warning">
                        {event.promoters} present
                    </p>

                    {/* Event Title */}
                    <h5 className="card-title text-danger">{event.title}</h5>

                    {/* Event Details */}
                    <p className="card-text">
                        {formatDate(event.date)}<br />
                        {formatTime(event.startTime)} {event.endTime ? `- ${formatTime(event.endTime)}` : ''}<br />
                        {venue?.name && <>{venue.name}<br /></>}
                        {venue?.city && venue?.state && (
                            <>
                                <FontAwesomeIcon icon={faMapMarkerAlt} /> {`${venue.city}, ${venue.state}`}<br />
                            </>
                        )}
                        {formatAgeRequirement(event.ageRequirement)}<br />
                    </p>

                    {/* Event Buttons */}
                    <div className="mt-3">
                        {event.facebookEventLink && (
                            <a
                                href={event.facebookEventLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-primary me-2"
                            >
                                <FontAwesomeIcon icon={faFacebookF} /> Event
                            </a>
                        )}
                        {event.ticketLink && (
                            <a
                                href={event.ticketLink}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="btn btn-success"
                            >
                                <FontAwesomeIcon icon={faTicketAlt} /> Tickets
                                {event.ticketPrice && ` (${event.ticketPrice})`}
                            </a>
                        )}
                    </div>

                    {/* Edit & Feature Buttons */}
                    {editEvents && (
                        <>
                            <button
                                className="btn btn-warning position-absolute top-0 end-0"
                                onClick={(e) => {
                                    e.stopPropagation();  // Prevent the parent div's onClick from firing
                                    handleEdit(event);    // Handle the actual edit logic
                                }}
                            >
                                Edit
                            </button>
                            <button
                                className="btn btn-light position-absolute top-0 start-0"
                                onClick={(e) => {
                                    e.stopPropagation(); // Prevent card click from firing
                                    toggleFeatured();
                                }}
                            >
                                <FontAwesomeIcon icon={isFeatured ? filledStar : emptyStar} />
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default EventCard;